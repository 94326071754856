import React, {
  useState, useRef, useEffect,
} from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTracking, useVertical, useGeoLocation } from 'lib/Hooks';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { useMyNewsStore } from 'store';

import { MYNEWS_ENABLED, SAVE_BUTTON_STYLE } from 'lib/brandFeatures';
import {
  INITIALIZED,
  AUTHENTICATED,
  UNAUTHENTICATED,
  ARTICLE,
  VIDEO,
  RECIPE,
  PRODUCT,
} from 'lib/myNewsConstants';

import { SaveCTA } from 'components/SaveCTA';
import { CTAWrapper } from 'components/SaveCTA/CTAWrapper';
import styles from './styles.module.scss';
import { ButtonRibbon, ButtonWithText, ButtonHeart } from './SaveButtons';

const CONTENT_TYPES = {
  article: ARTICLE,
  video: VIDEO,
  recipe: RECIPE,
  relatedRecipe: RECIPE,
  product: PRODUCT,
  liveBlog: ARTICLE,
};

const offsetProps = ['s-only', 's', 'm'];

/**
 * Save button component
 * @param {string} contentId - content id
 * @param {string} contentType - content type
 * @param {string} additionalClasses - additional classes
 * @param {boolean} options.isThumbnail - Button with shape background
 * @param {boolean} options.isTitle - Button right aligned
 * @param {string} options.offset - what breakpoint to apply offset styling; intended for use with thumbnail type
 * @param {boolean} options.showCTA - show SaveCTA when save button is clicked
 * @param {string} contentTitle - title or name of the content that will be saved
 */
export const Save = ({
  contentId, contentType, additionalClasses, navbarPlacement, options, callbacks,
}) => {
  const authenticationState = useMyNewsStore((state) => state.authenticationState);
  const authenticate = useMyNewsStore((state) => state.authenticate);
  const createBookmark = useMyNewsStore((state) => state.createBookmark);
  const deleteBookmark = useMyNewsStore((state) => state.deleteBookmark);
  const isContentBookmarked = useMyNewsStore((state) => state.isContentBookmarked);
  const isBookmarked = useMyNewsStore((state) => state.bookmarks[contentId]);

  const {
    isThumbnail, isTitle, setAsSaved, offset, pageRegion, showCTA,
  } = options;

  const showAsBookmarked = setAsSaved ?? isBookmarked;

  const [disableHover, setDisableHover] = useState(false);
  const [animateCTA, setAnimateCTA] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const { vertical } = useVertical();
  const isMyNewsEnabled = Boolean(getFeatureConfigForBrand(MYNEWS_ENABLED, vertical));
  const saveBtnStyle = getFeatureConfigForBrand(SAVE_BUTTON_STYLE, vertical);
  const { isChromeless } = useSelector(({ shared }) => shared);

  const isVideoPage = contentType === 'video';

  const track = useTracking('mbt_mynews_save_click');

  const timeoutRef = useRef(null);
  const shouldSave = useRef(false);
  const { isUsa } = useGeoLocation();
  const isAuthenticated = authenticationState === AUTHENTICATED;
  const isUnauthenticated = authenticationState === UNAUTHENTICATED;
  const isInitialized = isAuthenticated || isUnauthenticated || authenticationState === INITIALIZED;

  const shouldShow = !isChromeless && isUsa && contentId && isMyNewsEnabled;

  /**
   * Tracks the save click action.
   *
   * @param {string} action - The action to be tracked.
   */
  const trackSaveClick = (action) => {
    track({
      action,
      placement: 'share bar',
      contentId,
    });
  };

  const dataActivityMap = pageRegion?.includes('top')
    ? { 'data-activity-map': `social-share-links-${contentType}-top` }
    : {};

  const tooltipText = {
    unauthenticated: isVideoPage
      ? 'Create your free profile or log in to save this video'
      : 'Create your free profile or log in to save this article',
    authenticated: isVideoPage
      ? 'Save this video to watch later'
      : 'Save this article to read later',
  };

  /**
   *
   */
  const handleSave = async () => {
    setAnimateCTA(true);
    setTimeout(() => {
      setAnimateCTA(false);
    }, 5000);
    if (callbacks?.save) callbacks.save();
    await createBookmark({
      contentId,
      contentType: CONTENT_TYPES[contentType],
    });
    trackSaveClick('saved');
  };

  /**
   *
   */
  const handleLogin = () => {
    shouldSave.current = true;
    authenticate();
    trackSaveClick('login');
  };

  /**
   *
   */
  const handleRemove = async () => {
    if (callbacks?.remove) callbacks.remove();
    await deleteBookmark(contentId);
    trackSaveClick('remove');
  };

  /**
   *
   */
  const handleClick = () => {
    if (!isInitialized) return null;
    if (authenticationState === UNAUTHENTICATED) return handleLogin();
    // Disable hover state for 2 seconds after click
    clearTimeout(timeoutRef.current);
    setDisableHover(true);
    timeoutRef.current = setTimeout(() => setDisableHover(false), 2000);
    if (showAsBookmarked) return handleRemove();
    return handleSave();
  };

  useEffect(async () => {
    // if in view, check if content item is bookmarked
    if (contentId && inView && isAuthenticated && window.HFSapi.identity && !setAsSaved) {
      await isContentBookmarked(contentId);
    }
  }, [inView, contentId, authenticationState]);

  useEffect(() => {
    // User tried to save before authentication, save now
    if (!shouldSave.current || !isAuthenticated) return;
    shouldSave.current = false;
    if (!isBookmarked) handleSave();
  }, [isBookmarked]);

  // Hide component if content type is not supported
  if (!CONTENT_TYPES[contentType]) return null;

  // Hide component if not enabled
  if (!shouldShow) return null;

  const ButtonWithContentTypeShape = contentType === CONTENT_TYPES.product ? ButtonHeart : ButtonRibbon;
  const shouldShowCTA = animateCTA && showCTA;
  const ctaOffset = offset !== undefined;

  return (
    <CTAWrapper
      condition={shouldShowCTA}
      wrapper={(children) => (
        <div className={styles.ctaWrapper}>
          {children}
          { shouldShowCTA && <SaveCTA contentId={contentId} contentType={CONTENT_TYPES[contentType]} animateCTA offset={ctaOffset} /> }
        </div>
      )}
    >
      <div
        data-testid="save-button-container"
        {...dataActivityMap}
        className={classNames(styles.save, additionalClasses, 'save-button-container', {
          [styles.isSaved]: showAsBookmarked,
          [styles.isAuthenticated]: isAuthenticated,
          [styles.isInitialized]: isInitialized,
          [styles.disableHover]: disableHover,
          [styles.isThumbnail]: isThumbnail,
          [styles.isTitle]: isTitle,
          [styles[`offset-${offset}`]]: offset,
        })}
        ref={ref}
      >
        {saveBtnStyle === 'withText' ? (
          <>
            <ButtonWithText
              handleClick={handleClick}
              isItemBookmarked={showAsBookmarked}
              navbarPlacement={navbarPlacement}
              hiddenText="with a NBCUniversal Profile"
            />
            <div className={styles.tooltip}>
              <span>{tooltipText[isAuthenticated ? 'authenticated' : 'unauthenticated']}</span>
            </div>
          </>
        ) : (
          <ButtonWithContentTypeShape handleClick={handleClick} />
        )}
      </div>
    </CTAWrapper>
  );
};

Save.defaultProps = {
  additionalClasses: '',
  navbarPlacement: false,
  options: {
    isThumbnail: false,
    isTitle: false,
    offset: null,
    setAsSaved: undefined,
    pageRegion: '',
    showCTA: false,
  },
  callbacks: null,
};

Save.propTypes = {
  contentId: PropTypes.string.isRequired,
  navbarPlacement: PropTypes.bool,
  contentType: PropTypes.oneOf(Object.keys(CONTENT_TYPES)).isRequired,
  additionalClasses: PropTypes.string,
  options: PropTypes.shape({
    isThumbnail: PropTypes.bool,
    isTitle: PropTypes.bool,
    offset: PropTypes.oneOf(offsetProps),
    setAsSaved: PropTypes.bool,
    pageRegion: PropTypes.string,
    showCTA: PropTypes.bool,
  }),
  callbacks: PropTypes.shape({
    remove: PropTypes.func,
    save: PropTypes.func,
  }),
};
