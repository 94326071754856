import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SaveBorder from 'assets/icons/save-border.svg';
import SaveBorderThick from 'assets/icons/save-border-thick.svg';
import HeartBorderThick from 'assets/icons/heart-border-thick.svg';


import styles from './styles.module.scss';

// Screen reader only styles
const hiddenStyles = {
  display: 'inline-block',
  position: 'absolute',
  overflow: 'hidden',
  clip: 'rect(0 0 0 0)',
  height: 1,
  width: 1,
  margin: -1,
  padding: 0,
  border: 0,
};

/**
 * ButtonRibbon component renders a button with save icon and hover state.
 *
 * @param {object} props - The component props.
 * @param {Function} props.handleClick - The function to handle button click events.
 * @returns {JSX.Element} The rendered button component.
 */
export function ButtonRibbon({ handleClick }) {
  return (
    <button
      data-testid="save-button"
      type="button"
      className={styles.button}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleClick();
      }}
    >
      <span className={classNames('icon', 'icon-save-filled', styles.icon, styles.iconSave)} aria-hidden />
      <img
        className={classNames('icon', styles.icon, styles.iconSaveHover, styles.iconRibbon)}
        src={SaveBorderThick}
        alt="Save hover state"
        data-testid="icon-save-border-thick"
        aria-hidden
      />
      <span
        className={classNames('icon', 'icon-save-thick', styles.icon, styles.iconSaveAlt)}
        data-testid="icon-save-thick"
        aria-hidden
      />
    </button>
  );
}


ButtonRibbon.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

/**
 * ButtonHeart component renders a button with a heart icon and hover state.
 *
 * @param {object} props - The component props.
 * @param {Function} props.handleClick - The function to handle button click events.
 * @returns {JSX.Element} The rendered button component.
 */
export function ButtonHeart({ handleClick }) {
  return (
    <button
      data-testid="save-button"
      type="button"
      className={styles.button}
      onClick={(e) => {
        e.preventDefault();
        handleClick();
      }}
    >
      <span className={classNames('icon', 'icon-heart-filled', styles.icon, styles.iconSave)} />
      <img
        className={classNames(styles.icon, styles.iconSaveHover, styles.iconHeart)}
        src={HeartBorderThick}
        alt="Save hover state"
        data-testid="icon-heart"
      />
      <span
        className={classNames('icon', 'icon-heart-thick', styles.icon, styles.iconSaveAlt)}
        data-testid="icon-save-thick"
      />
    </button>
  );
}

ButtonHeart.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

/**
 * ButtonWithText component renders a button with save icon, hover state, and optional text.
 *
 * @param {object} props - The component props.
 * @param {Function} props.handleClick - The function to handle button click events.
 * @param {boolean} props.isItemBookmarked - Indicates if the item is bookmarked.
 * @param {boolean} [props.navbarPlacement=false] - Indicates if the button is placed in the navbar.
 * @param {string} [props.hiddenText=''] - Text for screen readers.
 * @returns {JSX.Element} The rendered button component.
 */
export function ButtonWithText({
  handleClick, isItemBookmarked, navbarPlacement, hiddenText,
}) {
  return (
    <>
      <button data-testid="save-button" type="button" className={styles.button} onClick={handleClick} aria-pressed={isItemBookmarked}>
        <span className={
          classNames(
            'icon',
            'icon-save-filled',
            styles.icon,
            styles.iconSave,
            {
              [styles.navbarPlacement]: navbarPlacement,
            },
          )
        }
        />
        <img
          data-testid="icon-ribbon"
          className={
            classNames(
              'icon',
              styles.icon,
              styles.iconSaveHover,
              styles.iconRibbon,
              {
                [styles.navbarPlacement]: navbarPlacement,
              },
            )
          }
          src={SaveBorder}
          alt="Save hover state"
          aria-hidden
        />
        <span
          className={
            classNames(
              'icon',
              'icon-save',
              styles.icon,
              styles.iconSaveAlt,
              {
                [styles.navbarPlacement]: navbarPlacement,
              },
            )
          }
          aria-hidden
        />
        {!isItemBookmarked && !navbarPlacement ? (
          <span
            className={styles.text}
            data-testid="nbc-save-button-text-save"
          >
            Save
            {Boolean(hiddenText.length) && <span style={hiddenStyles} data-testid="hidden-text">{hiddenText}</span>}
          </span>
        ) : null}
      </button>
      {isItemBookmarked && !navbarPlacement ? (
        <span className={styles.text} data-testid="nbc-save-button-text-goto">
          {'Go to '}
          <a className="share__saved-link" href="/my-news#activity">
            Saved
          </a>
        </span>
      ) : null}
    </>
  );
}

ButtonWithText.defaultProps = {
  navbarPlacement: false,
  hiddenText: '',
};

ButtonWithText.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isItemBookmarked: PropTypes.bool.isRequired,
  navbarPlacement: PropTypes.bool,
  hiddenText: PropTypes.string,
};
